@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: 'Noway', 'Pretendard', sans-serif;
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

:root {
  --total-move: 0vw;
  --safe-area-inset-bottom: env(safe-area-inset-bottom);
  --vh: calc(var(--vh, 1vh) * 1);
}
