@font-face {
  font-family: 'Noway';
  src: url('noway-thin-webfont.woff2');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Noway';
  src: url('noway-light-webfont.woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Noway';
  src: url('noway-regular-webfont.woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noway';
  src: url('noway-medium-webfont.woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Noway';
  src: url('noway-bold-webfont.woff2');
  font-weight: 900;
  font-style: normal;
}
